<template>
  <div class="bg-black text-slate-300 relative min-h-[850px] md:min-h-full">
    <div class="absolute w-full py-16">
      <Container class="space-y-16">
        <div class="grid grid-cols-[100px_200px]">
          <div>
            <NuxtImg
              src="/surya-bintang-logo-dark.png"
              alt="logo surya bintang adventure"
              format="webp"
              height="32"
              width="88"
            />
          </div>
          <div>
            <NuxtImg src="/logo-tripkaikai.png" alt="logo tripkaikai" format="webp" height="42" width="220" />
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div class="space-y-12">
            <div class="space-y-6">
              <div class="text-4xl font-normal" v-html="$t('footer.title')"></div>
              <div class="text-xl" v-html="$t('footer.desc')"></div>
              <div class="space-x-4">
                <a
                  href="https://www.instagram.com/suryabintang_adventures/"
                  target="_blank"
                  class="btn btn-square rounded-full"
                  aria-label="instagram"
                  rel="nofollow"
                >
                  <Icon name="teenyicons:instagram-outline" class="h-6 w-6" />
                </a>
                <a
                  href="https://www.facebook.com/suryabintangadventurebali"
                  target="_blank"
                  aria-label="Facebook"
                  class="btn btn-square rounded-full"
                  rel="nofollow"
                >
                  <Icon name="teenyicons:facebook-outline" class="w-6 h-6" />
                </a>
                <a
                  href="https://www.youtube.com/@suryabintangadventures"
                  target="_blank"
                  aria-label="Youtube"
                  class="btn btn-square rounded-full"
                  rel="nofollow"
                >
                  <Icon name="teenyicons:youtube-outline" class="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div class="space-y-8">
                <div class="font-bold mb-5">
                  {{ $t('footer.address_text') }}
                </div>
                <a
                  class="text-base lg:text-sm"
                  href="https://maps.app.goo.gl/JocV12A5kYAawctVA"
                  target="_blank"
                  rel="nofollow"
                >
                  Jl. Raya Payangan, Melinggih Kelod, Kec. Payangan, Kabupaten Gianyar, Bali 80572
                </a>
              </div>
              <div class="flex flex-col">
                <div class="font-bold mb-5">
                  {{ $t('footer.contact_text') }}
                </div>
                <a class="text-base lg:text-sm" target="_blank" href="https://wa.me/+62818881388" rel="nofollow">
                  +62 818-881-388
                </a>
                <!-- <a
                  class="text-base lg:text-sm"
                  href="mailto:reservation@suryabintang.com"
                  target="_blank"
                  rel="nofollow"
                >
                  reservation@suryabintang.com
                </a> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Copyright © PT. Karang Njung Makmur Sejahtera {{ currentYear }}</div>
        </div>
      </Container>
    </div>
    <div>
      <div class="grid place-items-end w-full">
        <NuxtImg
          src="/footer-background.png"
          class="h-[900px] lg:h-[618px]"
          alt="footer image"
          format="webp"
          width="490"
          height="618"
          sizes="(max-width: 640px) 100vw, 640px"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<style></style>
